
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.fias {
  padding: $base-gutter * 1.5 $base-gutter * 2;
  border-radius: $base-border-radius * 1.5;
  border: 1px solid $color-grayscale-10;
  @include for-size(phone-portrait-down) {
    padding: calc(#{$base-gutter} / 2) $base-gutter;
  }

  &__match-address {
    color: $primary-color-hover;
  }

  &__search {
    margin-bottom: $base-gutter;
  }

  &__selected-address {
    font-size: $font-size-base;
    display: flex;
    align-items: center;

    span {
      color: $primary-color-hover;
      padding: 0 calc(#{$base-gutter} / 4);
    }

    .esmp-button {
      .esmp-svg-icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}
